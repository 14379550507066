import Headerlogin from '../../component/headers/Headerlogin'
import LoginForm from '../../component/login-form/LoginForm'


export default function Login() {
  return (
    <main className = "login-main" >
       <Headerlogin />
       <LoginForm  />
    </main>
  )
}
