import React from 'react'
import HeaderSignup from '../../component/headers/HeaderSignup'
import SignupForm from '../../component/signup-form/SignupForm'

export default function Signup() {
  
  return (
    <main className="signup-main">
       <HeaderSignup />
       <SignupForm />
    </main>
  )
}
