import { originLocation } from "../../utilities/location";
import React, { useRef } from "react";
import { lettersAndNumbers, lettersRegex } from "../../utilities/regex";
import { useNavigate } from "react-router";
import { Form, Input, InputRef } from "antd";

export default function SignupForm() {
  const navigate = useNavigate();

  const userNameRef = useRef<InputRef | null>(null);
  const passwordRef = useRef<InputRef | null>(null);
  const confirmRef = useRef<InputRef | null>(null);
  const confirmLabelSpan = useRef<HTMLSpanElement | null>(null);
  const nameLabelSpan = useRef<HTMLSpanElement | null>(null);

  const signUp = async () => {
    const userName = userNameRef.current?.input?.value;
    const passwordValue = passwordRef.current?.input?.value;
    const passwordConfirmValue = confirmRef.current?.input?.value;
    if (
      userName === undefined ||
      passwordValue === undefined ||
      passwordConfirmValue === undefined ||
      confirmLabelSpan.current === null ||
      nameLabelSpan.current === null
    )
      return;

    if (!lettersRegex.test(userName)) return;
    if (!lettersAndNumbers.test(passwordValue)) return;
    if (passwordValue !== passwordConfirmValue) return;
    else confirmLabelSpan.current.innerText = "password don't match";
   
      let newData = {
        username: userName,
        password: passwordValue,
      };
      const req = JSON.stringify(newData);
      try {
        const response = await fetch(originLocation + "/signup", {
          method: "POST", // or 'PUT'
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: req,
        });
        const res = await response.json();

        if (res !== "user already exist") navigate("/login");
        else nameLabelSpan.current.innerText = res;
      } catch {
        console.error("Error:", false);
      }
  };

  return (
    <section className="signup-section">
      <div className="signup-card">
        <div className="signup-title">
          <h1>Sign up</h1>
        </div>

        <div className="signup-inputs">
          <div className="signup-input">
            <Form.Item htmlFor="username">
              User name <span ref={nameLabelSpan}></span>
            </Form.Item>
            <Input
              type="text"
              id="username"
              name="username"
              placeholder="User name"
              ref={userNameRef}
            />
          </div>
          <div className="signup-input">
            <Form.Item
              htmlFor="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters",
                },
                {
                  max: 20,
                  message: "Password must be less than 20 characters",
                },
                {
                  pattern: lettersAndNumbers,
                  message: "Password must contain letters and numbers",
                },
              ]}
            >
              Password
            </Form.Item>
            <Input
              type="password"
              id="password"
              name="password"
              ref={passwordRef}
            />
          </div>
          <div className="signup-input">
            <Form.Item
              htmlFor="password-confirm"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters",
                },
                {
                  max: 20,
                  message: "Password must be less than 20 characters",
                },
                {
                  pattern: lettersAndNumbers,
                  message: "Password must contain letters and numbers",
                },
              ]}
            >
              Password confirm<span ref={confirmLabelSpan}></span>
            </Form.Item>
            <Input
              type="password"
              id="password-confirm"
              name="password-confirm"
              ref={confirmRef}
            />
          </div>
        </div>
        <div className="signup-button signup-main-button  btn" onClick={signUp}>
          Sign up
        </div>
      </div>
    </section>
  );
}
