
import { generateCode } from '../../../utils/genrateCode';
import { useState } from 'react';
import bg from '../../../../../assets/backgrounds/bg-case.png';


export default function Case() {
  const [position, setPosition] = useState(0);
  const [seed ,setSeed] = useState(0)
  const [code , setCode] = useState<string|null>()

  return (
    <main className="flex min-h-screen  items-center  justify-start  text-white ">
      
        <img
          alt="background"
          src={bg}
          className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
        />
     
      <div className="absolute w-48 h-48 left-[calc(50vw-96px)] top-[calc(50vh-96px)] bg-white bg-opacity-25 rounded-full ">
        <div className="flex flex-col gap-3 justify-center items-center h-48">
          {position === 0 && (
            <div className="flex flex-col gap-4 justify-center items-center h-48 z-10">
              <div className="flex">
                <input
                  max="1000"
                  maxLength={6}
                  className="bg-transparent w-24 border-none "
                  defaultValue={20}
                  type="text"
                  onChange={(e) => setSeed(parseInt(e.currentTarget.value))}
                />
                <div>seed</div>{" "}
              </div>
              <div
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm"
                onClick={() => {
                  setCode(generateCode(seed.toString()));
                  setPosition(1)}}
              >
                Start
              </div>
            </div>
          )}
          {position === 1 && (
            <div className="flex flex-col gap-3 justify-center items-center h-48">
              <div
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-xl"
                onClick={() => setPosition(2)}
              >
                Open
              </div>
            </div>
          )}
       
      {position === 2 && (
        <div className="flex flex-col gap-3 justify-center items-center h-48">
          <div  className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-xl" >
            {code}
            </div>
        </div>
      )}
       </div>
      </div>
      </main>
  )
}

