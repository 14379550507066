import { originLocation } from "../utilities/location";

export const signupRequest = async (payload: {
  email: string;
  password: string;
}) => {
  try {
    const res = await fetch(originLocation + "/auth/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return res;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
