import DevelopmentTool from '../../__ReactTesterBack__/components/DevelopmentToolComponent'
import { asUserICanConnectSocket } from '../../__ReactTesterBack__/tests/asUserICanConnectSocket'
import { asUserICanLogin } from '../../__ReactTesterBack__/tests/asUserICanLogin'
import { asUserICanSignup } from '../../__ReactTesterBack__/tests/asUseriCanSigup'

export default function Test() {

      const tests: (()=> Promise<{message : string , state :  string }>)[] = [ asUserICanSignup , asUserICanLogin , asUserICanConnectSocket]

  return (
    <div>
    <DevelopmentTool  testFunctions={tests} />
    </div>
  )
}
