import { loginRequest } from "../../dependences/requests/loginRequest";
import { ResponseState } from "../models/responseState";
import { TestFunction } from "../models/testFunction";

export const asUserICanLogin: TestFunction = async () => {
  const response = await loginRequest("toto@gamil.com", "123456");
  if (response === undefined) {
    return { message: "User could not log in bad request", state: ResponseState.Incorrect };
  }
  const data = await response.json();
  if (data.jwt !== undefined) {
    return {
      message: "User logged in successfully",
      state: ResponseState.Correct,
    };
  }
  return { message: "User could not log in", state: ResponseState.Incorrect };
};
