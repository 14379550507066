"use client";

import { useCallback, useEffect, useState } from "react";
import bgBomb from "../../../../assets/backgrounds/bg-bomb.png";
import bgBoom from "../../../../assets/backgrounds/bg-boom.png";


export default function Bomb() {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState(0);
  const [timer, setTimer] = useState(120);
  const [code, setCode] = useState<string[]>([]);
  const [star, setStar] = useState<number>(0);
  const [memoryCode, setMemoryCode] = useState<string[]>([]);
  const [flash, setFlash] = useState(false);
  const [bombBip, setBombBip] = useState<HTMLAudioElement | null>(null);
  const [bombAmerced, setBombAmerced] = useState<HTMLAudioElement | null>(null);
  const [bombExplosion, setBombExplosion] = useState<HTMLAudioElement | null>(
    null
  );

  useEffect(() => {
    setBombAmerced(new Audio("/assets/sounds/bomb-amorced.mp3"));
    setBombBip(new Audio("/assets/sounds/bomb-bip.mp3"));
    setBombExplosion(new Audio("/assets/sounds/bomb-explosion.mp3"));
  }, []);

  const compose = (digit: number) => {

    if (code.length > 4 && position === 1) {
      setStar(0);
      setPosition(2);
      bombAmerced?.play();
      setCode([...code, digit.toString()]);
    
      return;
    }
    
    if (position === 2) {
      setMemoryCode([...memoryCode, digit.toString()]);
      setStar(star + 1);
      if (!bombBip) return;
      bombBip.currentTime = 0;
      bombBip.play();
      return;
    }

    bombBip?.play();
    setCode([...code, digit.toString()]);
    setStar(star + 1);
  };

  const checkCode = () => {
    const codeMemoryString = memoryCode.join("");
    if (!code.join("")?.includes(codeMemoryString)) {
      setStar(0);
      setMemoryCode([]);
    }
    if (code.length === memoryCode.length) {
      bombAmerced?.play();
      setPosition(4);
    }
  };

  useEffect(() => {
   if(memoryCode.length > 0 ) checkCode();
  }, [memoryCode]);

  
  useEffect(() => {
    let interval: number |  undefined;
    if (position === 2) {
      interval = window.setInterval(() => {
        setTimer((prev) => prev - 1);
        setFlash(!flash);
        if (timer <= 0) {
          bombExplosion?.play();
          setPosition(3);
          clearInterval(interval)
        }
      }, 1000);
    }
    if (timer <= 0) {
          bombExplosion?.play();
          setPosition(3);
          clearInterval(interval)
        }
    if (position === 4 || position === 3) clearInterval(interval);

    return () => clearInterval(interval);
  }, [position, timer]);

  const handleResize = useCallback(() => {
    const scaleWidth = (window.visualViewport?.width ?? window.innerWidth )/ 660  
  const scaleHeight = (window.visualViewport?.height ?? window.innerHeight) / 360   
  const scale = Math.min(scaleWidth, scaleHeight);
  setScale(scale);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="overflow-hidden w-[100dvw] h-[100dvh]">
    <main
      className="relative flex flex-col items-center bg-lime-950  justify-center  text-white  "
      style={{
        width: 660 + "px",
        height: 360 + "px",
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      {position !== 3 && (
        <img
          alt="background"
          src={bgBomb}
          height={360}
          width={660}
          className="absolute left-0 top-0 w-full object-cover  z-10"
        />
      )}
      {position === 3 && (
        <img
          alt="background"
          src={bgBoom}
          height={360}
          width={660}
          className="absolute left-0 top-0 w-full object-cover  z-10"
        />
      )}
      {position === 0 && (
        <div className="flex flex-col gap-3 justify-center items-center p-4 z-30 bg-white bg-opacity-25 rounded-md">
          <div className="flex">
            <input
              max="1000"
              maxLength={6}
              className="bg-transparent w-[15vw] border-none "
              defaultValue={timer.toString()}
              type="text"
              onChange={(e) => setTimer(parseInt(e.currentTarget.value))}
            />
            <div>sec</div>{" "}
          </div>
          <div
            className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm cursor-pointer"
            onClick={() => setPosition(1)}
          >
            Start
          </div>
        </div>
      )}

      {position <= 3 && (
        <div
          className={`z-50 absolute top-[179px] left-[300px] h-3 w-3 rounded-full ' ${
            flash ? "bg-red-500 bg-opacity-50 shadow-red-500 shadow-2xl" : ""
          }`}
        ></div>
      )}
      {position === 3 && (
        <div className="z-30 flex flex-col gap-3">
          <div className=" bg-white bg-opacity-50 rounded-lg p-4 text-3xl  ">
            Terrorist Win
          </div>
          <a
            href={"/discover"}
            className=" text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-6 text-lg"
          >
            Menu
          </a>
        </div>
      )}
      {position === 4 && (
        <div className="z-30 flex flex-col gap-3">
          <div className=" bg-white bg-opacity-50 rounded-lg p-4 text-3xl ">
            Counter-terrorist Win
          </div>
          <a
            href={"/discover"}
            className=" text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-6 text-lg"
          >
            Menu
          </a>
        </div>
      )}

      {position !== 3 && (
        <div className=" flex items-center justify-end h-[120px] w-[220px]  bg-black  absolute top-[50px] left-[340px] z-20 rounded-lg">
          <div className="text-red-500 font-bold text-2xl">{timer}</div>

          <div className="flex gap-4 absolute bottom-0 left-10 text-2xl">
            <div className={`${star < 1 ? "text-red-500" : "text-green-400"} `}>
              *
            </div>
            <div className={`${star < 2 ? "text-red-500" : "text-green-400"} `}>
              *
            </div>
            <div className={`${star < 3 ? "text-red-500" : "text-green-400"} `}>
              *
            </div>
            <div className={`${star < 4 ? "text-red-500" : "text-green-400"} `}>
              *
            </div>
            <div className={`${star < 5 ? "text-red-500" : "text-green-400"} `}>
              *
            </div>
            <div className={`${star < 6 ? "text-red-500" : "text-green-400"} `}>
              *
            </div>
          </div>
        </div>
      )}

      <div
        onClick={() => compose(1)}
        id="one"
        data-testid="1"
        className={`h-[30px] w-[30px]   absolute top-[235px] left-[340px] z-20 ${
          code[memoryCode.length ] === "1" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(2)}
        id="two"
        data-testid="2"
        className={`h-[30px] w-[30px]   absolute top-[235px] left-[390px] z-20 ${
          code[memoryCode.length ] === "2" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(3)}
        id="tree"
        data-testid="3"
        className={`h-[30px] w-[30px]   absolute top-[235px] left-[445px] z-20 ${
          code[memoryCode.length] === "3" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(4)}
        id="four"
        data-testid="4"
        className={`h-[30px] w-[30px]   absolute top-[235px] left-[495px] z-20 ${
          code[memoryCode.length] === "4" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(5)}
        id="five"
        data-testid="5"
        className={`h-[30px] w-[30px]   absolute top-[235px] left-[545px] z-20 ${
          code[memoryCode.length] === "5" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(6)}
        id="six"
        data-testid="6"
        className={`h-[30px] w-[30px]   absolute top-[295px] left-[340px] z-20 ${
          code[memoryCode.length] === "6" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(7)}
        id="seven"
        data-testid="7"
        className={`h-[30px] w-[30px]   absolute top-[295px] left-[390px] z-20 ${
          code[memoryCode.length] === "7" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(8)}
        id="height"
        data-testid="8"
        className={`h-[30px] w-[30px]   absolute top-[295px] left-[445px] z-20 ${
          code[memoryCode.length] === "8" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(9)}
        id="nine"
        data-testid="9"
        className={`h-[30px] w-[30px]   absolute top-[295px] left-[495px] z-20 ${
          code[memoryCode.length] === "9" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
      <div
        onClick={() => compose(0)}
        id="zero"
        data-testid="0"
        className={`h-[30px] w-[30px]   absolute top-[295px] left-[545px] z-20 ${
          code[memoryCode.length] === "0" && position === 2
            ? "bg-green-500 bg-opacity-50"
            : ""
        }`}
      ></div>
    </main>
    </div>
  );
}
