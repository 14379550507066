
import React, { useState } from 'react'
import bg from '../../../assets/backgrounds/bg-discover.png'


export default function CreateSalon() {
  // Définition des états pour les entrées du formulaire
  const [nomSalon, setNomSalon] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Gestion de la soumission du formulaire
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!nomSalon || !password || !confirmPassword) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
    // Ajoutez ici la logique pour créer le salon
    alert("Salon créé avec succès !");
  };

  return (
    <main className="flex min-h-screen h-screen flex-col items-center justify-start gap-6 py-6 text-white pt-24 overflow-auto">
      <img  
        alt='background'
        src={bg}
        className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
      />
      <div className="flex flex-col gap-2 p-4 bg-white bg-opacity-35 rounded-2xl w-4/5">
        <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nom du salon"
            className="p-2 rounded-lg"
            value={nomSalon}
            onChange={e => setNomSalon(e.target.value)}
          />
          <input
            type="password"
            placeholder="Mot de passe"
            className="p-2 rounded-lg"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirmer le mot de passe"
            className="p-2 rounded-lg"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <button type="submit" className="bg-blue-500 text-white rounded-lg p-2">Créer le salon</button>
        </form>
      </div>
      <a href={'/salon/create'} className="text-center w-4/5 bg-white bg-opacity-35 rounded-lg p-4 text-3xl">Salon</a>
    </main>
  );
}