import { originLocation } from "../utilities/location";

export const loginRequest = async (email: string, password: string) => {
  try {
    const res = await fetch(originLocation + "/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
    return res;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
