
import sniper from '../../../assets/backgrounds/bg-sniper.png'
import NavHeader from "../../component/headers/header";


export default function Home() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-end gap-6 py-6 text-white">
    <img  
    alt = 'background'
    src={sniper}
    className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
     />
     <NavHeader/>
     <div className="flex flex-col gap-2 p-4 bg-black bg-opacity-35 rounded-2xl w-4/5">
      <div className="text-base p-2 text-center">Elevate your airsoft experience with our ultimate app</div>
      <div className="text-xs">manage your teams, track your scores, and dominate the field like never before.</div>
      </div>
      <a className="text-center w-4/5 bg-lime-400 bg-opacity-35 rounded-lg p-4 text-3xl" href={'/login'}>Salon</a>
      <a href={'/discover'} className=" text-center w-4/5 bg-blue-400 bg-opacity-35 rounded-lg p-4 text-3xl">free Tools</a>
      <a href={'https://www.youtube.com/@beechhomma11'} className=" text-center w-4/5 bg-red-400 bg-opacity-35 rounded-lg p-4 text-3xl">YouTube Channel</a>
    </main>
  );
}