
import { signupRequest } from "../../dependences/requests/signupRequest";
import { ResponseState } from "../models/responseState";
import { TestFunction } from "../models/testFunction";


export const asUserICanSignup : TestFunction = async () => {
    const response = await signupRequest({email: "toto@gamil.com",password :  "123456"});
    if (response === undefined) {
        return { message: "User could not sign up bad request", state:ResponseState.Incorrect }
    }
    const data = await response.json();
    if (data !== undefined) {
        return { message: data, state:ResponseState.Correct }
    }
    return { message: "User could not sign up", state:ResponseState.Incorrect }
}