
import { useCallback, useEffect, useState } from "react";
import gold from "../../../../../assets/backgrounds/bg-gold.png";
import safeCode from "../../../../../assets/backgrounds/bg-safe-code.png";
import safe from "../../../../../assets/backgrounds/bg-safe.png";

import { generateCode } from "../../../utils/genrateCode";

export default function Safe() {
  const [position, setPosition] = useState(0);
  const [seed, setSeed] = useState(0);
  const [code, setCode] = useState<string|null>(null);
  const [codeMemory, setCodeMemory] = useState<string[]>([]);
  const [scale, setScale] = useState(1);


  const handleResize = useCallback(() => {
  const scaleWidth = (window.visualViewport?.width ?? window.innerWidth )/ 660  
  const scaleHeight = (window.visualViewport?.height ?? window.innerHeight) / 360   
  const scale = Math.min(scaleWidth, scaleHeight);
  setScale(scale);
  }, []);

useEffect(() => {
  const codeMemoryString = codeMemory.join('')
  if (code === null || codeMemory.length === 0 )return 
  if (!code?.includes(codeMemoryString)) {
    setCodeMemory([])
  } 
   if (code === codeMemoryString) {
    setPosition(2)
  }
},[codeMemory])


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); 

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [handleResize]);

  return (
    <div className="overflow-hidden w-[100dvw] h-[100dvh]">
     <main
    onClick={handleResize}
      className="relative flex flex-col items-center bg-lime-950  justify-center  text-white "
      style={{
        width: (660) + "px",
        height: (360) + "px",
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      {position === 0 ? (
        <img
          alt="background"
          src={safe}
          height={360}
          width={660}
          className="absolute left-0 top-0 w-full  object-cover  z-10"
        />
      ) : position === 1 ? (
        <img
          alt="background"
          src={safeCode}
          height={360}
          width={660}
          className="absolute left-0 top-0 w-full object-cover  z-10"
        />
      ) : (
        <img
          alt="background"
          src={gold}
          height={360}
          width={660}
          className="absolute left-0 top-0 w-full object-cover  z-10"
        />
      )}

      {position === 0 && (
        <div className="relative w-48 h-48  bg-white bg-opacity-25 rounded-full  z-20">
          <div className="flex flex-col gap-3 justify-center items-center h-48">
            <div className="flex flex-col gap-4 justify-center items-center h-48">
              <div className="flex">
                <input
                  max="1000"
                  maxLength={6}
                  className="bg-transparent w-24 border-none "
                  defaultValue={20}
                  type="text"
                  onChange={(e) => setSeed(parseInt(e.currentTarget.value))}
                />
                <div>seed</div>{" "}
              </div>
              <button
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm "
                onClick={() => {setPosition(1)
                  setCode(generateCode(seed.toString()));
                }}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      )}
      {position === 1 && (
        <div className="absolute top-0 left-0   z-30 text-black text-center">
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[415px] top-[130px] flex justify-center items-center"
            id={"one"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '1'])
            }
          >
            1
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[467px] top-[130px] flex justify-center items-center"
            id={"two"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '2'])
            }
          >
            2
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[520px] top-[130px] flex justify-center items-center"
            id={"tree"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev,'3'])
            }
          >
            3
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[572px] top-[130px] flex justify-center items-center"
            id={"four"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '4'])
            }
          >
            4
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[415px] top-[182px] flex justify-center items-center"
            id={"five"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev,'5'])
            }
          >
            5
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[467px] top-[182px] flex justify-center items-center"
            id={"six"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '6'])
            }
          >
            6
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[520px] top-[182px] flex justify-center items-center"
            id={"seven"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev,  '7'])
            }
          >
            7
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[570px] top-[182px] flex justify-center items-center"
            id={"height"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '8'])
            }
          >
            8
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[413px] top-[235px] flex justify-center items-center"
            id={"#"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '#'])
            }
          >
            #
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[464px] top-[235px] flex justify-center items-center"
            id={"nine"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '9'])
            }
          >
            9
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[516px] top-[235px] flex justify-center items-center"
            id={"zero"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev, '0'])
            }
          >
            0
          </div>
          <div
            className="cursor-pointer h-8 w-8 bg-transparent absolute left-[569px] top-[235px] flex justify-center items-center"
            id={"*"}
            onClick={(e) =>
              setCodeMemory((prev) => [...prev,  '*'])
            }
          >
            *
          </div>

          <div className="absolute px-4  py-2 rounded-lg gap-4 bg-black flex items-center justify-around left-[295px] top-[315px]">
            <div data-testid = '*0' className={ codeMemory.length > 0 ? 'text-green-500' : "text-red-500"}>*</div>
            <div data-testid = '*1' className={ codeMemory.length > 1 ? 'text-green-500' : "text-red-500"}>*</div>
            <div data-testid = '*2' className={ codeMemory.length > 2 ? 'text-green-500' : "text-red-500"}>*</div>
            <div data-testid = '*3' className={ codeMemory.length > 3 ? 'text-green-500' : "text-red-500"}>*</div>
            <div data-testid = '*4' className={ codeMemory.length > 4 ? 'text-green-500' : "text-red-500"}>*</div>
            <div data-testid = '*5' className={ codeMemory.length > 5 ? 'text-green-500' : "text-red-500"}>*</div>
          </div>
        </div>
      )}

      {position === 2 && (
        <div className="flex flex-col gap-3 justify-center items-center h-48">
          <a
            href={"/discover"}
            className="text-center  bg-lime-500 bg-opacity-45 rounded-lg py-2 px-4 text-sm z-20"
          >
            You Win
          </a>
        </div>
      )}
    </main> 
    </div>
  );
}
