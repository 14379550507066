
import { useNavigate } from "react-router";
import { originLocation } from "../../utilities/location";
import { lettersAndNumbers, lettersRegex } from "../../utilities/regex";



export default function LoginForm() {
  const navigate = useNavigate();

  // const userNameRef = useRef<InputRef | null>(null);
  // const passwordRef = useRef<InputRef | null>(null);

  const login = async (values: any) => {
    const userName = values.userName;
    const passwordValue = values.passwordValue;
    if (userName === undefined || passwordValue === undefined) return;

    if (lettersRegex.test(userName) && lettersAndNumbers.test(passwordValue)) {
      const newData = {
        username: userName,
        password: passwordValue,
      };

      try {
        const response = await fetch(originLocation + "/login", {
          method: "POST", // or 'PUT'
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(newData),
        });

        let newJwtToken = await response.json();
        // let dataBuffer = stateDatas
        // dataBuffer.jwtToken = newJwtToken.accessToken
        // await setStateDatas(dataBuffer)
        navigate("/loby");
      } catch {
        alert("An error occurred during login. Please try again.");
      }
    } else console.log(false);
  };

  return (
    <section className="login-section">
      <div className="login-card">
        <div className="login-title">
          <h1>Log in</h1>
        </div>

        <form className="login-inputs" onSubmit={login}>
          <label
            htmlFor="username"
            
          >Username</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Username"
          />

          <label
            htmlFor={"password"}
          
          >Password</label>
          <input type="password" id="password" name="password" placeholder="Password"/>
          <button type="submit">Log in</button>
        </form>
      </div>
    </section>
  );
}
