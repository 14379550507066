import TestRunnerComponent from "./TestRunnerComponent";

/**
 * Component representing a development tool.
 * 
 * @param {Object} props - The props object containing the following properties:
 * @param {Function[]} props.testFunctions - An array of functions representing test cases.
 * 
 * @returns {JSX.Element} JSX element representing the development tool component.
 */
interface DevProps {
testFunctions : (()=> Promise<{message : string , state :  string }>)[]
}

const DevelopmentTool = ({ testFunctions  } : DevProps) => {

  return (
    
    <div className="w-full h-full mx-auto p-4 bg-gray-100 rounded-lg shadow-md">
      {/* { process.env.NODE_ENV === 'development' && */}
      <TestRunnerComponent testFunctions={testFunctions}/>
      {/* } */}
    </div>
  );
};

export default DevelopmentTool;
