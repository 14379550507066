import React, { useEffect, useState } from "react";
import { ResponseState } from '../models/responseState';

interface TestResult {
  name: any;
  result: string;
  message: string;
}

interface TestRunnerProps {
  testFunctions : (()=> Promise<{message :string , state :string}>)[]
}

const TestRunnerComponent = ({ testFunctions } : TestRunnerProps) => {
  const [testResults, setTestResults] = useState<TestResult[]>([]);
  const [loopCount, setLoopCount] = useState(1);
  const [failedTests, setFailedTests] = useState<(() => Promise<{message :string , state :string}>)[]>([]);
  const [totalTime, setTotalTime] = useState<number>(0);
const [testCount, setTestCount] = useState<number>(0);
  const handleLoopCountChange = (e : React.ChangeEvent<HTMLInputElement>) => {
   if (parseInt(e.target.value) > 0) setLoopCount(parseInt(e.target.value));
   else setLoopCount(0)
  };
  useEffect(() => {
    // Exécuter les tests à chaque changement dans testFunctions
    runTests(testFunctions);
  }, [testFunctions, loopCount]); 

  const runTests = async (tests : (()=>  Promise<{message :string , state :string}>)[]) => {
    const start = performance.now();
    const newTestResults : TestResult[] = [];
    const newFailedTests : (() =>  Promise<{message :string , state :string}>) [] = [];
    

    for (let i = 0; i < loopCount; i++) {
      for (const test of tests) {
        setTestCount(i +1);
        const result: { state: string; message: string } = await test();
        const newResult : TestResult = {
          name: test.name,
          result: result.state,
          message: result.message,
        };
        newTestResults.push(newResult);
        if (result.state === ResponseState.Incorrect) {
          newFailedTests.push(test);
        }
      }
    }
    const uniqueResults = Array.from(new Set(newTestResults.map(result => JSON.stringify(result))))
  .map(result => JSON.parse(result) as TestResult);

    const uniqueFailedTests = Array.from(new Set(newFailedTests))
    .sort((a, b) => a.name.localeCompare(b.name));

    const end = performance.now();
    const timeTaken = end - start;
    setTotalTime(timeTaken);

    setTestResults(uniqueResults);
    setFailedTests(uniqueFailedTests);
  };

  return (
    <div className="p-4 bg-gray-100">
      <button
        onClick={() => runTests(testFunctions)}
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
      >
        Run All Tests {loopCount} times
      </button>
      <button
        onClick={() => runTests(failedTests)}
        className="ml-4 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
      >
        Run Only Failed Tests
      </button>
      <div className="flex items-center mt-4">
        <label htmlFor="loopCount" className="block text-gray-700 font-bold">
          Loop Count:
        </label>
        <input
          type="number"
          value={loopCount}
          onChange={handleLoopCountChange}
          className="ml-4 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-bold">Test Results:</h2>
        <p>Runned Tests: {testCount}</p>
        <p>Total Time Taken: {totalTime/1000} second</p>
        <ul className="mt-2">
          {testResults.map((result, index) => (
            <li key={index} className="mb-2">
              <strong className="mr-2">{result.name}</strong>
              {result.result === ResponseState.Incorrect ? (
                <span className="text-red-600">
                  Incorrect: {result.message}
                </span>
              ) : (
                <span className="text-green-600">
                  Correct: {result.message}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TestRunnerComponent;
