
import powerPlant from "../../../../assets/backgrounds/bg-power-plant.png";
import powerPlantExplosion from "../../../../assets/backgrounds/bg-power-plant-explosion.png";
import powerPlantOverHeating from "../../../../assets/backgrounds/bg-power-plant-over-heating.png";
import React, { useEffect, useState } from "react";

export default function NuclearPlant() {
  const [position, setPosition] = useState<number>(0);
  const [fullTime, setFulltime] = useState<number>(30);
  const [timer, setTimer] = useState<number>(30);
  const [alarm, setAlarm] = useState<HTMLAudioElement | null>(null);
  const [explosion, setExplosion] = useState<HTMLAudioElement | null>(null);
  useEffect(() => {
    setAlarm(new Audio("/assets/sounds/nuclear-siren.mp3"));
    setExplosion(new Audio("/assets/sounds/bomb-explosion.mp3"));
  }, []);

  useEffect(() => {
    if (position >= 1) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            setPosition(3);
            alarm?.pause();
            if (alarm) {
              alarm.currentTime = 0;
            }
            explosion?.play();

            clearInterval(interval);
            return 0; // Garde le timer à 0 au lieu de devenir négatif
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [position]);

  useEffect(() => {
    if (position === 0) return;
    if (timer > 10) {
      alarm?.pause();
      if (alarm) {
        alarm.currentTime = 0;
      }
      setPosition(1);
    }
    if (timer < 10) {
      alarm?.play();
      setPosition(2);
    }
    if(timer<=0){
      setPosition(3);
      alarm?.pause();
      if (alarm) {
        alarm.currentTime = 0;
      }
      explosion?.play();
    }
  }, [timer]);

  return (
    <main className="flex min-h-screen  items-center  justify-center  text-white ">
      {position <= 1 && (
        <img
          alt="background"
          src={powerPlant}
          className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
        />
      )}
      {position === 2 && (
        <img
          alt="background"
          src={powerPlantOverHeating}
          className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
        />
      )}
      {position === 3 && (
        <img
          alt="background"
          src={powerPlantExplosion}
          className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
        />
      )}
      {position === 0 && (
        <div className="relative w-48 h-48  bg-black bg-opacity-50 rounded-full z-20">
          <div className="flex flex-col gap-3 justify-center items-center h-48">
            <div className="flex flex-col gap-4 justify-center items-center h-48">
              <div className="flex">
                <input
                  max="1000"
                  maxLength={6}
                  className="bg-transparent w-24 border-none "
                  defaultValue={fullTime.toString()}
                  type="text"
                  onChange={(e) => {
                    setFulltime(parseInt(e.currentTarget.value));
                    setTimer(parseInt(e.currentTarget.value));
                  }}
                />
                <div>sec</div>{" "}
              </div>
              <button
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm "
                onClick={() => {
                  setPosition(1);
                }}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      )}
      {(position === 1 || position === 2) && (
        <div className="relative w-48 h-48  bg-black bg-opacity-50 rounded-full  z-20">
          <div className="flex flex-col gap-3 justify-center items-center h-48">
            <div className="flex flex-col gap-4 justify-center items-center h-48">
              <div className="flex">
                <div>{timer} sec</div>
              </div>
              <button
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm "
                onClick={() => {
                  setTimer(fullTime);
                }}
              >
                Cool down
              </button>
            </div>
          </div>
        </div>
      )}
      {position === 3 && (
        <div className="relative w-48 h-48  bg-black bg-opacity-50 rounded-full  z-20">
          <div className="flex flex-col gap-3 justify-center items-center h-48">
            <div className="flex flex-col gap-4 justify-center items-center h-48">
              <div className="flex">
                <div>You Lose!</div>
              </div>
              <a
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm "
                href={"/discover"}
              >
                menu
              </a>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
