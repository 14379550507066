
import bg from '../../../assets/backgrounds/bg-discover.png'
import NavHeader from '../../component/headers/header';


export default function Salon() {
  return (
    <main className="flex min-h-screen h-screen flex-col items-center  justify-start gap-6 py-6 text-white pt-24 overflow-auto">
    <img  
    alt = 'background'
    src={bg}
    className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
     />
     <NavHeader/>
     <div className="flex flex-col gap-2 p-4 bg-white bg-opacity-35 rounded-2xl w-4/5  ">
      <div className="text-base p-2 text-center">some nice online tools for the community</div>
      <div className="text-xs">Hey mate these tools where free . Enjoy !!!</div>
    </div>
      <a href={'/salon/create'} className="text-center w-4/5 bg-white bg-opacity-35 rounded-lg p-4 text-3xl">Create</a>
      <a href={'/salon/join'} className=" text-center w-4/5 bg-white bg-opacity-35 rounded-lg p-4 text-3xl">Join</a>
    </main>
  );
}
