
import { useEffect, useState } from "react";
import bgTimerBattle from "../../../../assets/backgrounds/bg-timer-battle.png";
import bgTimerEnd from "../../../../assets/backgrounds/bg-timer-end.png";
import bgTimerPreparation from "../../../../assets/backgrounds/bg-timer-preparation.png";
import bgTimerPresset from "../../../../assets/backgrounds/bg-timer-presset.png";

export default function Timer() {
  const [config, setConfig] = useState({ preparation: 30, battle: 210 });
  const [phase, setPhase] = useState("config"); // 'config', 'preparation', 'battle', 'end'
  const [time, setTime] = useState(0);

  const startPreparation = () => {
    setTime(config.preparation);
    setPhase("preparation");
  };

  const endGame = () => setPhase("end");
  const nextPhase = () => {
    if (phase === "preparation") {
      setTime(config.battle);
      setPhase("battle");
    } else if (phase === "battle") {
      endGame();
    }
  };
  const resetGame = () => {
    setConfig({ preparation: 30, battle: 210 });
    setPhase("config");
  };

  useEffect(() => {
    if (phase === "config") return;
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      nextPhase();
    }
  }, [time, phase]);

  return (
    <div className="text-white">
      {phase === "config" && (
        <div>
          <img
            alt="background"
            src={bgTimerPresset}
            className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
          />
          <div className="absolute w-56 h-56 left-[calc(50vw-112px)] top-[calc(50vh-112px)] bg-black bg-opacity-50 rounded-full pt-4">
            <div className="flex flex-col gap-3 justify-center items-center h-56">
              <div>
                <div className="flex flex-col gap-1 justify-center items-center ">
                  Setup (sec) :
                </div>
                <input
                  type="number"
                  name="preparation"
                  className="bg-white bg-opacity-25 w-[15vw] border-none "
                  value={config.preparation}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      [e.target.name]: parseInt(e.target.value, 10),
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-1 justify-center items-center ">
                <div>Battle (sec) :</div>
                <input
                  type="number"
                  name="battle"
                  value={config.battle}
                  className="bg-white bg-opacity-25 w-[15vw] border-none "
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      [e.target.name]: parseInt(e.target.value, 10),
                    })
                  }
                />
              </div>
              <div
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm"
                onClick={startPreparation}
              >
                Start
              </div>
            </div>
          </div>
        </div>
      )}
      {phase === "preparation" && (
        <div>
          <img
            alt="background"
            src={bgTimerPreparation}
            className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
          />
          <div className="absolute w-56 h-56 left-[calc(50vw-112px)] top-[calc(50vh-112px)] bg-black bg-opacity-50 rounded-full pt-4">
            <div className="flex flex-col gap-3 justify-center items-center h-56">
              <h1>Préparation</h1>
              <div>{time}</div>
            </div>
          </div>
        </div>
      )}
      {phase === "battle" && (
        <div>
          <img
            alt="background"
            src={bgTimerBattle}
            className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
          />
          <div className="absolute w-56 h-56 left-[calc(50vw-112px)] top-[calc(50vh-112px)] bg-black bg-opacity-50 rounded-full pt-4">
            <div className="flex flex-col gap-3 justify-center items-center h-56">
              <h1>Bataille</h1>
              <div>{time}</div>
            </div>
          </div>
        </div>
      )}
      {phase === "end" && (
        <div>
          <img
            alt="background"
            src={bgTimerEnd}
            className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
          />
          <div className="absolute w-56 h-56 left-[calc(50vw-112px)] top-[calc(50vh-112px)] bg-black bg-opacity-50 rounded-full pt-4">
            <div className="flex flex-col gap-3 justify-center items-center h-56">
              <h1>Finish !</h1>
              <div onClick={resetGame}  className="text-center w-4/5 bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-xl">Reset</div>
              <a  className="text-center w-4/5 bg-white bg-opacity-35 rounded-lg py-2 px-4 text-xl" href={'/discover'} onClick={resetGame}>Menu</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
