
import React, { useEffect, useState } from "react";
import bg from "../../../../assets/backgrounds/bg-flag.png";
import flagIcon from "../../../../assets/icon/flag.png";

export default function Flag() {
  const [timer, setTimer] = useState(120);
  const [position, setPosition] = useState(0);
  const [redScore, setRedScore] = useState(0)
  const [blueScore, setBlueScore] = useState(0)
  const [blueOrRed , setBlueOrRed] = useState('')
  const [captureSound ,setCaptureSound] = useState<HTMLAudioElement|null>(null);
  const [victorySound , setVictorySound] = useState<HTMLAudioElement|null>(null);

useEffect(() => {
  setCaptureSound(new Audio('/assets/sounds/flag-capture.mp3'))
  setVictorySound(new Audio('/assets/sounds/flag-victory.mp3'))
}, [])

  const captureFlag = (e : React.MouseEvent<HTMLDivElement>) => {
    if (position !== 1) return
    if (e.currentTarget.id !== blueOrRed) {
setBlueOrRed(e.currentTarget.id)
captureSound?.play()    
}

  }

  useEffect(() => {
    let interval: number  | undefined;
    if (position === 1) {
      interval = window.setInterval(() => {
        if (blueOrRed === 'Blue') {
          setBlueScore((prevScore) => {
            if (prevScore + 1 >= timer) {
              setPosition(2);
              victorySound?.play()
              clearInterval(interval);
            }
            return prevScore + 1;
          });
        } else if (blueOrRed === 'Red') {
          setRedScore((prevScore) => {
            if (prevScore + 1 >= timer) {
              setPosition(2);
              victorySound?.play()
              clearInterval(interval);
            }
            return prevScore + 1;
          });
        }
      }, 1000);
    }
  
    return () => clearInterval(interval);
  }, [position, blueOrRed, timer, redScore, blueScore]);


  return (
    <main className="flex min-h-screen  items-center  justify-start  text-white ">
      <img
        alt="background"
        src={bg}
        className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
      />
      <div className="absolute w-48 h-48 left-[calc(50vw-96px)] top-[calc(50vh-96px)] bg-white bg-opacity-25 rounded-full ">
        <div className="flex flex-col gap-3 justify-center items-center h-48">
          {position === 0 && (
            <div className="flex flex-col gap-3 justify-center items-center h-48 z-10">
              <div className="flex">
                <input
                  max="1000"
                  maxLength={6}
                  className="bg-transparent w-[15vw] border-none "
                  defaultValue={timer.toString()}
                  type="text"
                  onChange={(e) => setTimer(parseInt(e.currentTarget.value))}
                />
                <div>sec</div>{" "}
              </div>
              <div
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm"
                onClick={() => setPosition(1)}
              >
                Start
              </div>
            </div>
          )}
          {position >= 1 && (
            <div className="flex flex-col gap-3 justify-center items-center h-48">
              <img alt="background" src={flagIcon} className="w-16 h-16 z-30" />
              <div className="z-30">capture</div>
            </div>
          )}
         
        </div> 
       
      </div>
 {position === 2 && (
  <div className="w-[100vw] absolute  top-3/4 flex justify-center items-center flex-col gap-2 z-20">
            <div className=" bg-white bg-opacity-25 rounded-xl px-6 py-2 ">{blueOrRed} Win</div>
            <a href={'/discover'} className=" text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-6 text-lg">Menu</a>
         </div> )}
      <div data-testid = 'Blue' id="Blue" className={`relative h-screen w-[50vw] bg-blue-400 ${ blueOrRed === 'Blue' ? 'bg-opacity-45' : 'bg-opacity-35' }`} onClick={captureFlag}>
        <div className="ml-2 z-50 relative" data-testid = 'blue-score'> score : {blueScore}</div>
        <div className={`absolute left-0 top-0 w-[50vw] bg-blue-500 bg-opacity-50 z-10 `} style={{height: `${((blueScore / timer) * 100)}vh` }}></div>
      </div>
      <div  data-testid = 'Red' id="Red" className={`relative h-screen w-[50vw] bg-red-400 ${ blueOrRed === 'Red' ? 'bg-opacity-45' : 'bg-opacity-35' }`} onClick={captureFlag}><div className="relative ml-2 z-50" data-testid = 'red-score'> score : {redScore}</div>
      <div className={`absolute left-0 top-0 w-[50vw] bg-red-500 bg-opacity-50 z-10 `} style={{height: `${((redScore / timer) * 100)}vh` }}></div>
      </div>
    </main>
  );
}
