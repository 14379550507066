import { useEffect, useState } from "react";
import bgEnd from "../../../../assets/backgrounds/bg-respawn-end.png";
import bg from "../../../../assets/backgrounds/bg-respawn.png";

export default function Respawn() {
  const [position, setPosition] = useState(0);
  const [life, setLife] = useState(20);

  useEffect(() => {
    if (life <= 0) setPosition(2);
  }, [life]);

  return (
    <main className="flex min-h-screen  items-center  justify-start  text-white ">
      {position !== 2 ? (
        <img
          alt="background"
          src={bg}
          className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
        />
      ) : (
        <img
          alt="background"
          src={bgEnd}
          className="absolute left-0 top-0 h-screen object-cover w-screen -z-10"
        />
      )}
      <div className="absolute w-48 h-48 left-[calc(50vw-96px)] top-[calc(50vh-96px)] bg-white bg-opacity-25 rounded-full ">
        <div className="flex flex-col gap-3 justify-center items-center h-48">
          {position === 0 && (
            <div className="flex flex-col gap-4 justify-center items-center h-48 z-10">
              <div className="flex">
                <input
                  max="1000"
                  maxLength={6}
                  className="bg-transparent w-24 border-none "
                  defaultValue={20}
                  type="text"
                  onChange={(e) => setLife(parseInt(e.currentTarget.value))}
                />
                <div>life</div>{" "}
              </div>
              <div
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm"
                onClick={() => setPosition(1)}
              >
                Start
              </div>
            </div>
          )}
          {position === 1 && (
            <div className="flex flex-col gap-3 justify-center items-center h-48">
              <div>{life} life</div>
              <div
                className="text-center  bg-lime-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm"
                onClick={() => setLife((prevLife) => prevLife - 1)}
              >
                Respawn
              </div>
            </div>
          )}
       
      {position === 2 && (
        <div className="flex flex-col gap-3 justify-center items-center h-48">
          <div>{life} life</div>
          <a
           href={'/discover'}
            className="text-center  bg-red-400 bg-opacity-35 rounded-lg py-2 px-4 text-sm"
          >
            You Lose
          </a>
        </div>
      )}
       </div>
      </div>
    </main>
  );
}
