import icon1 from "../../../assets/icon/soldier.png"
import icon2 from "../../../assets/icon/weapon.png"

export default function NavHeader() {
  return (
    <div data-testid="nav-header" className="absolute left-0 top-0 w-screen flex flex-col bg-opacity-25 items-center pt-2  bg-black">
      
      <div className="flex w-screen justify-between items-center px-3">
        
        <a className="flex items-end font-bold text-2xl h-[35px]" href="/">
          <div className="leading-4">RealStrike</div>
          <img width={35} height={35} src={icon2} alt="icon" />
          <div className="leading-4 relative right-3">Arena</div>
        </a>
        <div className="border border-white border-opacity-20 shadow-md rounded-full bg-white p-2 bg-opacity-25">
          <img src={icon1} alt="icon" />
        </div>
      </div>

      <div className="text-sm  tracking-[1em] relative top-2 spa">
        real condition
      </div>
    </div>
  );
}
