import dataCase from "../../../../assets/backgrounds/bg-case.png";
import safe from "../../../../assets/backgrounds/bg-safe.png";

export default function Code() {
  return (
    <main className="flex min-h-screen h-screen flex-col items-center  justify-around py-6 text-white  overflow-auto">
      <img
          alt="background"
          src={dataCase}
          className="absolute left-0 top-0 h-[50vh] object-cover w-screen -z-10"
        />  <img
          alt="background"
          src={safe}
          className="absolute left-0 top-1/2 h-[50vh] object-cover w-screen -z-10"
        />
      <a
        href={"/discover/code/data-case"}
        className=" text text-center w-4/5 bg-black bg-opacity-50 rounded-lg p-4 text-3xl"
      >
       
        Data case
      </a>
      <a
        href={"/discover/code/safe"}
        className=" text-center w-4/5 bg-black bg-opacity-50 rounded-lg p-4 text-3xl "
      >
      
        Safe
      </a>
    </main>
  );
}
