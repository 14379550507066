import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./output.css";
import "./index.css";
import Home from "./dependences/pages/home/Home";
import Login from "./dependences/pages/login/Login";
import Signup from "./dependences/pages/signup/Signup";
import PrivateRoute from "./dependences/privateRoute/PrivateRoute";
import Discover from "./dependences/pages/discover/page";
import Bomb from "./dependences/pages/discover/bomb/page";
import Code from "./dependences/pages/discover/code/page";
import Case from "./dependences/pages/discover/code/data-case/page";
import Safe from "./dependences/pages/discover/code/safe/page";
import Flag from "./dependences/pages/discover/flag/page";
import NuclearPlant from "./dependences/pages/discover/nuclear-plant/page";
import Respawn from "./dependences/pages/discover/respawn/page";
import Timer from "./dependences/pages/discover/timer/page";
import Salon from "./dependences/pages/salon/Salon";
import CreateSalon from "./dependences/pages/salon/CreateSalon";
import Test from "./dependences/test/test";

export const Switch = () => {
  const [stateDatas, setStateDatas] = useState({
    jwtToken: null,
    socket: null,
  }); //state data array for entire application

  // const getSocket = () => {
  //   const token = stateDatas.jwtToken; // get jwt token from local storage or cookie
  //   let dataBuffer = stateDatas;
  //   //  let socket = socketio.call(location, {
  //   //     query: { token }
  //   //   });
  //   // dataBuffer.socket = socket
  //   setStateDatas(dataBuffer);
  // };

  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/login"
          element={<Login/>}
        />
          <Route path="/test" element={<Test/>} />
          <Route path="/discover" element={<Discover />}/>
          <Route path="/discover/bomb" element={<Bomb />} />
          <Route path="/discover/code" element={<Code />} />
          <Route path="/discover/code/data-case" element={<Case />} />
          <Route path="/discover/code/safe" element={<Safe />} />
          <Route path="/discover/flag" element={<Flag />} />
          <Route path="/discover/nuclear-plant" element={<NuclearPlant />} />
          <Route path="/discover/respawn" element={<Respawn />} />
          <Route path="/discover/timer" element={<Timer />} />
      
        <Route
          path="/salon"
          element={
            <PrivateRoute stateDatas={stateDatas}>
              <Salon/>
            </PrivateRoute>
          }
       / >
        <Route path="/salon/create" element={<CreateSalon />} /> 
        {/*https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5*/}
        {/*<Route path = "/game" element={<PrivateRoute stateDatas={stateDatas} ><Game stateDatas={stateDatas} setStateDatas ={setStateDatas}/></PrivateRoute>} />*/}
      </Routes>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Switch />
      </Router>
    </div>
  );
}

export default App;
