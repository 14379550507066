import { io } from "socket.io-client";
import { ResponseState } from "../models/responseState";
import { TestFunction } from "../models/testFunction";
import { originLocation } from "../../dependences/utilities/location";

export const asUserICanConnectSocket: TestFunction = async () => {
    return new Promise((resolve, reject) => {
        const socket = io(originLocation);

        socket.on("connect", () => {
            console.log("Connected to socket server");
            socket.disconnect();
            resolve({
                message: "User connected to socket successfully",
                state: ResponseState.Correct,
            });
        });

        socket.on("connect_error", (error) => {
            console.log("Connection to socket server failed", error);
            socket.disconnect();
            resolve({
                message: "User could not connect to socket: " + error.message,
                state: ResponseState.Incorrect,
            });
        });
    });
    }
